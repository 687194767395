import React from "react"
import writers from "../json/writers.json"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"
import styled from "styled-components"
import Div8095 from "../components/utils/Div8095"
import HeadingCard from "../components/utils/HeadingCard"
import ContainerDiv from "../components/utils/ContainerDiv"
import NotAvailable from "../components/utils/NotAvailable"
import SurveyLink from "../components/storypage/SurveyLink"
import MoreStories from "../components/utils/MoreStories"

const StyledDiv = styled.div`
  background-color: white;
  padding: 1em;
  margin-top: 1em;
`
const StyledPic = styled.img`
  display: block;
  width: 80%;
  margin: 0 auto 1em auto;
  @media (max-width: 750px) {
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 1em;
  color: inherit;
  text-decoration: none;
  font-style: italic;
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: #2a3546;
  }
`
const StyledStory = styled.div`
  margin: 1em 0;
  padding: 0;
  p {
    margin: 0 0 1em 0;
    padding: 0;
  }
`

const Story = ({ pageContext }) => {
  const now = new Date()
  const releasedate = new Date(pageContext.releasedate)
  const writer = writers[pageContext.writerID]
  return (
    <Layout>
      <SEO title={pageContext.title} />
      <ContainerDiv bg={writer.color}>
        <Div8095>
          {now > releasedate ? (
            <StyledDiv>
              <StyledPic
                src={require(`../images/stories/${pageContext.pic}`)}
                alt={pageContext.fullname}
              />
              <HeadingCard w="60%">
                {pageContext.title} &mdash; {pageContext.fullname}
              </HeadingCard>

              <StyledLink to={"/writers/" + writer.slug}>
                {" "}
                &mdash;by {writer.fullname}
              </StyledLink>

              <StyledStory
                dangerouslySetInnerHTML={{ __html: pageContext.story }}
              />
              <MoreStories />
              <SurveyLink />
            </StyledDiv>
          ) : (
            <NotAvailable csDate={pageContext.comingsoon} />
          )}
        </Div8095>
      </ContainerDiv>
    </Layout>
  )
}

export default Story
