import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  color: inherit;
  font-style: italic;
  text-decoration: none;
  border-bottom: solid 1px black;
  transition: border-color 1s linear;
  &:hover {
    border-color: transparent;
  }
`
const MoreStories = () => (
  <StyledLink to="/stories">Read more stories here</StyledLink>
)

export default MoreStories
