import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledDiv = styled.div`
  text-align:center;
  background-color: white;
  min-height: 60vh;
  padding: 3em 1em 1em 1em;
  margin-top: 1em;
  h3{
	  font-size: 2em;
  }
`
const NotAvailable = ({ csDate }) => (
  <StyledDiv>
    <h3 className="rancho">Coming soon - {csDate}</h3>
    <p>
      This story is not yet published. Please check out all the published{" "}
      <Link to="/stories">stories</Link> on our stories page.
    </p>
  </StyledDiv>
)

export default NotAvailable
